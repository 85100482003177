import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
const isIADE = instance.includes("IADE");

export default {
	schemaType: "template",
	displayName: "Modal",
	component: "Modal",
	dataPacks: ["MODALS"],
	category: "Modals",
	type: {
		label: "Modals",
		value: "modals",
		mode: "detail",
	},
	content: [
		{
			title: "Start Date",
			type: "DateField",
			key: "startDate",
			hideable: true,
		},
		{
			title: "End Date",
			type: "DateField",
			key: "endDate",
			hideable: true,
		},
		{
			title: "Heading",
			type: "TextField",
			key: "heading",
		},
		{
			title: "Event Date",
			type: "DateField",
			key: "eventDate",
			hideable: true,
		},
		{
			title: "Event Location",
			type: "TextField",
			key: "eventLocation",
			hideable: true,
		},
		{
			title: "Event Hour",
			type: "TextField",
			key: "eventHour",
			helptext: "hh:mm",
			hideable: true,
		},
		{
			type: "ComponentContainer",
			title: "Visual content",
			whiteList: ["Image", "Video"],
			key: "visualContent",
			helptext:
				"In the case of Image, the recommended resolution is 736x550px.",
			hideable: true,
		},

		{
			title: "Modal type",
			type: "Select",
			key: "modalType",
			mandatory: true,
			options: [
				{
					value: "boxBanner",
					label: "Box Banner",
				},
				{
					value: "dropdownBanner",
					label: "Dropdown banner",
				},
				{
					value: "slideLeft",
					label: "Slide left",
				},
				{
					value: "slideRight",
					label: "Slide right",
				},
			],
		},

		{
			title: "Style",
			key: "theme",
			type: "VisualUniqueSelection",
			mandatory: true,
			options: [
				{
					...createThemeStyles("ipam-theme", [
						"accentAlt",
						"defaultAlt",
						"accentLight",
					]),
				},
				{
					...createThemeStyles("iade-theme", [
						"accentAlt",
						"defaultAlt",
						"accentLight",
					]),
				},
				{ ...createThemeStyles("iade-es-theme", ["defaultAlt", "accentAlt"]) },
				{
					...createThemeStyles("uddi-theme", [
						"defaultAlt",
						"accent",
						"accentAlt",
					]),
				},
			],
			columns: 8,
		},

		{
			title: "Content",
			type: "FieldGroup",
			key: "content",
			fields: [
				{
					title: "Wysiwyg",
					type: "RichText",
					key: "wysiwyg",
					hideable: true,
				},
				{
					title: "CTA",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "cta",
					hideable: true,
				},
				{
					title: "File",
					type: "FileField",
					key: "file",
				},
				{
					title: "File name",
					type: "TextField",
					key: "fileName",
				},
			],
		},

		{
			title: "Target pages",
			type: "ReferenceField",
			sources: [
				{ structuredData: "STAFF" },
				{ structuredData: "BLOG" },
				{ structuredData: "INFO_LANDINGS" },
				{ structuredData: "STUDY" },
				{ structuredData: "NEWS" },
				{ structuredData: "EVENTS" },
			],
			key: "target",
		},

		{
			title: "Modal trigger",
			type: "CheckGroup",
			key: "trigger",
			options: [
				{
					value: "showInHome",
					title: "Show in home",
					name: "showInHome",
				},
				{
					value: "showInAllSite",
					title: "Show in all the site",
					name: "showInAllSite",
				},
				{
					value: "showInAllStudyLists",
					title: "Show in all Study Lists",
					name: "showInAllStudyLists",
				},
			],
		},
	],

	default: {
		type: "template",
		templateType: "Modal",
		heading: "Lorem ipsum",
		modalType: "boxBanner",
		wysiwyg: "Lorem ipsum",
		cta: {
			component: "Button",
			buttonText: "Link",
			appearance: "secondary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		visualContent: {
			image: {
				component: "Image",
			},
			video: {
				component: "Video",
			},
		},
		target: {
			mode: "auto",
			order: "alpha",
			sources: [],
			quantity: 0,
		},
		theme: isIADE ? "defaultAlt" : "accentAlt",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Modal/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Modal/thumbnail@2x.png",
	},
};
