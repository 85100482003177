import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Enhanced Multiproduct Hero Form",
	component: "EnhancedMultiproductHeroForm",
	category: "interactive",
	dataPacks: ["STUDY_LANDING"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Entitled",
					type: "TextField",
					key: "entitled",
				},
				{
					title: "Icon Entitled",
					type: "ImageField",
					key: "iconEntitled",
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					mandatory: false,
					humanReadable: true,
				},
				{
					title: "Form options",
					type: "CheckGroup",
					key: "formOptions",
					options: [
						{
							value: "hideStudyTypeField",
							title: "Hide Study Type Field",
							name: "hideStudyTypeField",
						},
					],
				},
				{
					title: "Background Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "backgroundImage",
					mandatory: true,
					helptext: "Recommended minimum image size: 2508x1030",
				},
				{
					title: "Phone",
					type: "TextField",
					key: "phoneNumber",
					hideable: true,
				},
				{
					title: "Conditional form",
					type: "ConditionalField",
					key: "conditionalForm",
					mandatory: false,
					hideable: true,
					options: [
						{
							value: true,
							title: "QuickFacts + Information Request Form",
							name: "QuickFacts",
						},
					],
					fields: [
						{
							title: "Quick Facts",
							type: "ComponentContainer",
							whiteList: ["QuickFacts"],
							key: "quickFacts",
							mandatory: false,
							condition: true,
						},
					],
				},
			],
		},
	],

	default: {
		component: "EnhancedMultiproductHeroForm",
		title: { content: "Title", tag: "h2" },
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
		formOptions: null,
		phoneNumber: "+34 91 514 53 30",
		entitled: "",
		entitledIcon: "",
		quickFacts: {
			component: "QuickFacts",
			elements: [
				{
					component: "QuickFactsElement",
					title: "Lorem Ipsum",
					icon: "",
					description: "Lorem ipsum dolor ",
				},
				{
					component: "QuickFactsElement",
					title: "Lorem Ipsum",
					icon: "",
					description: "Lorem ipsum dolor ",
				},
			],
		},
		backgroundImage: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			veil: 0,
		},
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/EnhancedMultiproductHeroForm_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/EnhancedMultiproductHeroForm_x2.png`,
	},
});
