export default {
	domain: "https://res.cloudinary.com",
	uploadFolder: "image/upload",
	fallback404Image: "dx-placeholders/fallback-404-image",
	quality: "100",
	crop: "fill",
	gravity: "faces:center",
	format: "webp",
	loading: "lazy",
	backgroundLazy: false,
	decoding: "auto",
};
