const FormFields = {
	content: [
		{
			title: "Form Title",
			type: "TextField",
			humanReadable: true,
			key: "formTitle",
			hideable: true,
		},
		{
			title: "Form Subtitle",
			type: "RichText",
			humanReadable: true,
			key: "formSubtitle",
			hideable: true,
		},
		{
			type: "UniqueCheck",
			options: [{ title: "Sticky CTA" }],
			key: "stickyCTA",
			helptext:
				"Uncheck this checkbox if your landing page has no content and you want the form to be visible on mobile and tablet.",
		},
		{
			title: "Form confirmation message title",
			type: "TextField",
			humanReadable: true,
			key: "confirmationMessageTitle",
		},
		{
			title: "Form confirmation message detail",
			type: "TextField",
			humanReadable: true,
			key: "confirmationMessageDetail",
		},
		{
			title: "Form error message title",
			type: "TextField",
			humanReadable: true,
			key: "errorMessageTitle",
		},
		{
			title: "Form error message detail",
			type: "TextField",
			humanReadable: true,
			key: "errorMessageDetail",
		},
		{
			title: "Form button text",
			type: "TextField",
			humanReadable: true,
			key: "buttonText",
		},
	],
	defaults: {
		formTitle: "Title",
		formSubtitle: "Subtitle",
		confirmationMessageTitle: "Lorem ipsum.",
		confirmationMessageDetail:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		errorMessageTitle: "Lorem ipsum.",
		errorMessageDetail:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		buttonText: "Enviar",
		stickyCTA: true,
	},
};

export default FormFields;
