import pt_PT from "@translations/pt_PT";
import { deepClone } from "@translations/utils";

const iadePT = deepClone(pt_PT);

iadePT.form.header.req = "Sabe mais sobre este programa";
iadePT.form.header.reqSub =
	"Envia-nos a tua informação e entraremos em contacto brevemente";
iadePT.form.header.requestLong =
	"Preenche o formulário com os teus dados para solicitar informações";
iadePT.form.header.subtitle =
	"Deixa-nos os teus dados e entraremos em contacto contigo brevemente";
iadePT.filters.search = "Escreve aqui a tua pesquisa";
iadePT.searchResults.titleWithoutResults = "O que procuras?";

export default iadePT;
