import es_ES from "@translations/es_ES";
import { deepClone } from "@translations/utils";

const cegES = deepClone(es_ES);

cegES.form.button = "Acepto, contadme";
cegES.form.header.subtitle =
	"Déjanos tus datos y nos pondremos en contacto contigo.";
cegES.form.header.text = `Llámanos al <a href="tel:+34662988037">+34 662 98 80 37</a> o déjanos tus datos y nos pondremos en contacto contigo.`;

export default cegES;
