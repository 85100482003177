import { createSchema } from "@utils/index";

const QuickFactIcons = (icons) =>
	icons.map((icon) => {
		return {
			value: icon,
			title: icon.toUpperCase(),
			name: `${icon}`,
		};
	});

export default createSchema({
	schemaType: "component",
	displayName: "Quick Facts Element",
	component: "QuickFactsElement",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Icon",
					type: "RadioGroup",
					options: QuickFactIcons([
						"Dedication",
						"Duration",
						"Ects",
						"Language",
						"Learning_option",
						"Location",
						"Schedule",
						"Start",
					]),
					key: "icon",
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
	],
	default: {
		component: "QuickFactsElement",
		title: "Lorem ipsum ",
		icon: "",
		description: "Lorem ipsum dolor sit amet",
	},
	thumbnails: {},
});
