import { mapLibrary } from "@adapters";
import { Component } from "@griddo/core";
import { Suspense, lazy } from "react";

const Header = lazy(() =>
	import("./Header" /* webpackChunkName: "module---src-Header-js" */)
);

const HeaderLanding = lazy(() =>
	import(
		"./HeaderLanding" /* webpackChunkName: "module---src-HeaderLanding-js" */
	)
);

const HeaderOneLine = lazy(() =>
	import(
		"./HeaderOneLine" /* webpackChunkName: "module---src-HeaderOneLine-js" */
	)
);

const HeaderTwoLines = lazy(() =>
	import(
		"./HeaderTwoLines" /* webpackChunkName: "module---src-HeaderTwoLines-js" */
	)
);

const Accordion = lazy(() =>
	import("./Accordion" /* webpackChunkName: "module---src-Accordion-js" */)
);

const AddressCollection = lazy(() =>
	import(
		"./AddressCollection" /* webpackChunkName: "module---src-AddressCollection-js" */
	)
);

const AlumniForm = lazy(() =>
	import("./AlumniForm" /* webpackChunkName: "module---src-AlumniForm-js" */)
);

const AdmissionAttachmentsForm = lazy(() =>
	import(
		"./AdmissionAttachmentsForm" /* webpackChunkName: "module---src-AdmissionAttachmentsForm-js" */
	)
);

const AdmissionForm = lazy(() =>
	import(
		"./AdmissionForm" /* webpackChunkName: "module---src-AdmissionForm-js" */
	)
);

const AwardForm = lazy(() =>
	import("./AwardForm" /* webpackChunkName: "module---src-AwardForm-js" */)
);

const AwardsDistributor = lazy(() =>
	import(
		"./AwardsDistributor" /* webpackChunkName: "module---src-AwardsDistributor-js" */
	)
);

const BasicContent = lazy(() =>
	import(
		"./BasicContent" /* webpackChunkName: "module---src-BasicContent-js" */
	)
);
const BlogCategoriesMenu = lazy(() =>
	import(
		"./BlogCategoriesMenu" /* webpackChunkName: "module---src-BlogCategoriesMenu-js" */
	)
);
const BlogDistributor = lazy(() =>
	import(
		"./BlogDistributor" /* webpackChunkName: "module---src-BlogDistributor-js" */
	)
);

const CardCollection = lazy(() =>
	import(
		"./CardCollection" /* webpackChunkName: "module---src-CardCollection-js" */
	)
);

const CareerOpportunities = lazy(() =>
	import(
		"./CareerOpportunities" /* webpackChunkName: "module---src-CareerOpportunities-js" */
	)
);

const CarouselSlide = lazy(() =>
	import(
		"./CarouselSlide" /* webpackChunkName: "module---src-CarouselSlide-js" */
	)
);

const ContactForm = lazy(() =>
	import("./ContactForm" /* webpackChunkName: "module---src-ContactForm-js" */)
);

const CypherCollection = lazy(() =>
	import(
		"./CypherCollection" /* webpackChunkName: "module---src-CypherCollection-js" */
	)
);

const Distributor = lazy(() =>
	import("./Distributor" /* webpackChunkName: "module---src-Distributor-js" */)
);

const DownloadBox = lazy(() =>
	import("./DownloadBox" /* webpackChunkName: "module---src-DownloadBox-js" */)
);

const EnhancedMultiproductHeroForm = lazy(() =>
	import(
		"./EnhancedMultiproductHeroForm" /* webpackChunkName: "module---src-EnhancedMultiproductHeroForm-js" */
	)
);

const EnhancedProductHeroForm = lazy(() =>
	import(
		"./EnhancedProductHeroForm" /* webpackChunkName: "module---src-EnhancedProductHeroForm-js" */
	)
);

const EventAgenda = lazy(() =>
	import("./EventAgenda" /* webpackChunkName: "module---src-EventAgenda-js" */)
);

const EventDistributor = lazy(() =>
	import(
		"./EventDistributor" /* webpackChunkName: "module---src-EventDistributor-js" */
	)
);

const EventHeroForm = lazy(() =>
	import(
		"./EventHeroForm" /* webpackChunkName: "module---src-EventHeroForm-js" */
	)
);

const FeaturedAudiovisual = lazy(() =>
	import(
		"./FeaturedAudiovisual" /* webpackChunkName: "module---src-FeaturedAudiovisual-js" */
	)
);

const FeaturedBlock = lazy(() =>
	import(
		"./FeaturedBlock" /* webpackChunkName: "module---src-FeaturedBlock-js" */
	)
);

const FeaturedImage = lazy(() =>
	import(
		"./FeaturedImage" /* webpackChunkName: "module---src-FeaturedImage-js" */
	)
);

const Footer = lazy(() =>
	import("./Footer" /* webpackChunkName: "module---src-Footer-js" */)
);

const FooterLanding = lazy(() =>
	import(
		"./FooterLanding" /* webpackChunkName: "module---src-FooterLanding-js" */
	)
);

const GroupingCollection = lazy(() =>
	import(
		"./GroupingCollection" /* webpackChunkName: "module---src-GroupingCollection-js" */
	)
);

const HeroCard = lazy(() =>
	import("./HeroCard" /* webpackChunkName: "module---src-HeroCard-js" */)
);

const HeroEcommerce = lazy(() =>
	import(
		"./HeroEcommerce" /* webpackChunkName: "module---src-HeroEcommerce-js" */
	)
);

const HeroHome = lazy(() =>
	import("./HeroHome" /* webpackChunkName: "module---src-HeroHome-js" */)
);

const HeroStudy = lazy(() =>
	import("./HeroStudy" /* webpackChunkName: "module---src-HeroStudy-js" */)
);
const HeroLanding = lazy(() =>
	import("./HeroLanding" /* webpackChunkName: "module---src-HeroLanding-js" */)
);

const HighlightedDegrees = lazy(() =>
	import(
		"./HighlightedDegrees" /* webpackChunkName: "module---src-HighlightedDegrees-js" */
	)
);

const HighLightedCTA = lazy(() =>
	import(
		"./HighLightedCTA" /* webpackChunkName: "module---src-HighLightedCTA-js" */
	)
);

const HighlightedCarousel = lazy(() =>
	import(
		"./HighlightedCarousel" /* webpackChunkName: "module---src-HighlightedCarousel-js" */
	)
);

const HighlightedDownload = lazy(() =>
	import(
		"./HighlightedDownload" /* webpackChunkName: "module---src-HighlightedDownload-js" */
	)
);

const HorizontalTabs = lazy(() =>
	import(
		"./HorizontalTabs" /* webpackChunkName: "module---src-HorizontalTabs-js" */
	)
);

const InfoRequestForm = lazy(() =>
	import(
		"./InfoRequestForm" /* webpackChunkName: "module---src-InfoRequestForm-js" */
	)
);

const InnerTitle = lazy(() =>
	import("./InnerTitle" /* webpackChunkName: "module---src-InnerTitle-js" */)
);

const Introduction = lazy(() =>
	import(
		"./Introduction" /* webpackChunkName: "module---src-Introduction-js" */
	)
);

const LandingForm = lazy(() =>
	import("./LandingForm" /* webpackChunkName: "module---src-LandingForm-js" */)
);

const ModuleWrapper = lazy(() =>
	import(
		"./ModuleWrapper" /* webpackChunkName: "module---src-ModuleWrapper-js" */
	)
);

const MultiBrandHeader = lazy(() =>
	import(
		"./MultiBrandHeader" /* webpackChunkName: "module---src-MultiBrandHeader-js" */
	)
);
const FooterLandingSimple = lazy(() =>
	import(
		"./FooterLandingSimple" /* webpackChunkName: "module---src-FooterLandingSimple-js" */
	)
);
const MultiproductHeroForm = lazy(() =>
	import(
		"./MultiproductHeroForm" /* webpackChunkName: "module---src-MultiproductHeroForm-js" */
	)
);

const NewsDistributor = lazy(() =>
	import(
		"./NewsDistributor" /* webpackChunkName: "module---src-NewsDistributor-js" */
	)
);

const OpenDayForm = lazy(() =>
	import("./OpenDayForm" /* webpackChunkName: "module---src-OpenDayForm-js" */)
);

const Overview = lazy(() =>
	import("./Overview" /* webpackChunkName: "module---src-Overview-js" */)
);

const OverviewEcommerce = lazy(() =>
	import(
		"./OverviewEcommerce" /* webpackChunkName: "module---src-OverviewEcommerce-js" */
	)
);

const PT_InfoRequestForm = lazy(() =>
	import(
		"./PT_InfoRequestForm" /* webpackChunkName: "module---src-PT_InfoRequestForm-js" */
	)
);

const ProductHeroForm = lazy(() =>
	import(
		"./ProductHeroForm" /* webpackChunkName: "module---src-ProductHeroForm-js" */
	)
);

const ProgramBox = lazy(() =>
	import("./ProgramBox" /* webpackChunkName: "module---src-ProgramBox-js" */)
);

const RelatedPrograms = lazy(() =>
	import(
		"./RelatedPrograms" /* webpackChunkName: "module---src-RelatedPrograms-js" */
	)
);

const Search = lazy(() =>
	import("./Search" /* webpackChunkName: "module---src-Search-js" */)
);

const SocialShare = lazy(() =>
	import("./SocialShare" /* webpackChunkName: "module---src-SocialShare-js" */)
);

const SponsorsLogos = lazy(() =>
	import(
		"./SponsorsLogos" /* webpackChunkName: "module---src-SponsorsLogos-js" */
	)
);

const SponsorsLogosLightbox = lazy(() =>
	import(
		"./SponsorsLogosLightbox" /* webpackChunkName: "module---src-SponsorsLogosLightbox-js" */
	)
);

const StaffBio = lazy(() =>
	import("./StaffBio" /* webpackChunkName: "module---src-StaffBio-js" */)
);

const StaffCarouselLanding = lazy(() =>
	import(
		"./StaffCarouselLanding" /* webpackChunkName: "module---src-StaffCarouselLanding-js" */
	)
);

const StaffDistributor = lazy(() =>
	import(
		"./StaffDistributor" /* webpackChunkName: "module---src-StaffDistributor-js" */
	)
);

const StickyFooterCTA = lazy(() =>
	import(
		"./StickyFooterCTA" /* webpackChunkName: "module---src-StickyFooterCTA-js" */
	)
);

const StudyAgregator = lazy(() =>
	import(
		"./StudyAgregator" /* webpackChunkName: "module---src-StudyAgregator-js" */
	)
);

const StudyDistributor = lazy(() =>
	import(
		"./StudyDistributor" /* webpackChunkName: "module---src-StudyDistributor-js" */
	)
);

const StudyFixedMenu = lazy(() =>
	import(
		"./StudyFixedMenu" /* webpackChunkName: "module---src-StudyFixedMenu-js" */
	)
);

const StudyStaff = lazy(() =>
	import("./StudyStaff" /* webpackChunkName: "module---src-StudyStaff-js" */)
);

const Table = lazy(() =>
	import("./Table" /* webpackChunkName: "module---src-Table-js" */)
);

const Testimonials = lazy(() =>
	import(
		"./Testimonials" /* webpackChunkName: "module---src-Testimonials-js" */
	)
);

const TestimonialsLanding = lazy(() =>
	import(
		"./TestimonialsLanding" /* webpackChunkName: "module---src-TestimonialsLanding-js" */
	)
);

const VerticalCardCollection = lazy(() =>
	import(
		"./VerticalCardCollection" /* webpackChunkName: "module---src-VerticalCardCollection-js" */
	)
);

const VideoPlaylist = lazy(() =>
	import(
		"./VideoPlaylist" /* webpackChunkName: "module---src-VideoPlaylist-js" */
	)
);

const FAQ = lazy(() =>
	import("./FAQ" /* webpackChunkName: "module---src-FAQ-js" */)
);

const JobOfferTextBlock = lazy(() =>
	import(
		"./JobOfferTextBlock" /* webpackChunkName: "module---src-JobOfferTextBlock-js" */
	)
);
const JobOfferTextBlockWithIcon = lazy(() =>
	import(
		"./JobOfferTextBlockWithIcon" /* webpackChunkName: "module---src-JobOfferTextBlockWithIcon-js" */
	)
);
const JobOfferDistributor = lazy(() =>
	import(
		"./JobOfferDistributor" /* webpackChunkName: "module---src-JobOfferDistributor-js" */
	)
);
const LandingEventList = lazy(() =>
	import(
		"./LandingEventList" /* webpackChunkName: "module---src-LandingEventList-js" */
	)
);
const LandingEventForm = lazy(() =>
	import(
		"./LandingEventForm" /* webpackChunkName: "module---src-LandingEventForm-js" */
	)
);
const LandingInfoForm = lazy(() =>
	import(
		"./LandingInfoForm" /* webpackChunkName: "module---src-LandingInfoForm-js" */
	)
);

const FormType = lazy(() =>
	import("./FormType" /* webpackChunkName: "module---src-FormType-js" */)
);

const FormTypeGenericInfoLanding = lazy(() =>
	import(
		"./FormTypeGenericInfoLanding" /* webpackChunkName: "module---src-FormTypeGenericInfoLanding-js" */
	)
);
const FormTypeProductLanding = lazy(() =>
	import(
		"./FormTypeProductCollection" /* webpackChunkName: "module---src-FormTypeProductLanding-js" */
	)
);
const FormTypeDualProductLanding = lazy(() =>
	import(
		"./FormTypeDualProductLanding" /* webpackChunkName: "module---src-FormTypeDualProductLanding-js" */
	)
);
const FormTypeAreaLanding = lazy(() =>
	import(
		"./FormTypeAreaLanding" /* webpackChunkName: "module---src-FormTypeAreaLanding-js" */
	)
);
const FormTypeDualAreaLanding = lazy(() =>
	import(
		"./FormTypeDualAreaLanding" /* webpackChunkName: "module---src-FormTypeDualAreaLanding-js" */
	)
);
const FormTypeOtherInfoLanding = lazy(() =>
	import(
		"./FormTypeOtherInfoLanding" /* webpackChunkName: "module---src-FormTypeOtherInfoLanding-js" */
	)
);

const LandingStudyDistributor = lazy(() =>
	import(
		"./LandingStudyDistributor" /* webpackChunkName: "module---src-LandingStudyDistributor-js" */
	)
);
const LandingQuickFacts = lazy(() =>
	import(
		"./LandingQuickFacts" /* webpackChunkName: "module---src-LandingQuickFacts-js" */
	)
);

const FormTypeMultievent = lazy(() =>
	import(
		"./FormTypeMultievent" /* webpackChunkName: "module---src-FormTypeMultievent-js" */
	)
);
const FormTypeWebinar = lazy(() =>
	import(
		"./FormTypeWebinar" /* webpackChunkName: "module---src-FormTypeWebinar-js" */
	)
);
const FormTypeEasyPack = lazy(() =>
	import(
		"./FormTypeEasyPack" /* webpackChunkName: "module---src-FormTypeEasyPack-js" */
	)
);
const LandingIntroduction = lazy(() =>
	import(
		"./LandingIntroduction" /* webpackChunkName: "module---src-LandingIntroduction-js" */
	)
);

const ViewMore = lazy(() =>
	import("./ViewMore" /* webpackChunkName: "module---src-ViewMore-js" */)
);

const LandingCTA = lazy(() =>
	import("./LandingCTA" /* webpackChunkName: "module---src-LandingCTA-js" */)
);

const Filters = lazy(() =>
	import(
		"./ViewMore/Filters" /* webpackChunkName: "component---src-FooterCookiesLink-js" */
	)
);
const FormTypeMultiProduct = lazy(() =>
	import(
		"./FormTypeMultiProduct" /* webpackChunkName: "component---src-FormTypeMultiProduct-js" */
	)
);

const LandingTMGForm = lazy(() =>
	import(
		"./LandingTMGForm" /* webpackChunkName: "component---src-LandingTMGForm-js" */
	)
);

const FormTypeTMG = lazy(() =>
	import(
		"./FormTypeTMG" /* webpackChunkName: "component---src-FormTypeTMG-js" */
	)
);

const AlumniListPagination = lazy(() =>
	import(
		"./AlumniListPagination" /* webpackChunkName: "component---src-AlumniListPagination-js" */
	)
);

const EXPORTS = {
	Accordion,
	AddressCollection,
	AlumniListPagination,
	AlumniForm,
	AdmissionAttachmentsForm,
	AdmissionForm,
	AwardForm,
	AwardsDistributor,
	BasicContent,
	BlogCategoriesMenu,
	BlogDistributor,
	CardCollection,
	CareerOpportunities,
	CarouselSlide,
	ContactForm,
	CypherCollection,
	Distributor,
	DownloadBox,
	EnhancedMultiproductHeroForm,
	EnhancedProductHeroForm,
	EventAgenda,
	EventDistributor,
	EventHeroForm,
	FeaturedAudiovisual,
	FeaturedBlock,
	FeaturedImage,
	Footer,
	FooterLanding,
	GroupingCollection,
	Header,
	HeaderLanding,
	HeaderOneLine,
	HeaderTwoLines,
	HeroCard,
	HeroEcommerce,
	HeroHome,
	HeroStudy,
	HeroLanding,
	HighLightedCTA,
	HighlightedCarousel,
	HighlightedDegrees,
	HighlightedDownload,
	HorizontalTabs,
	InfoRequestForm,
	InnerTitle,
	Introduction,
	LandingForm,
	ModuleWrapper,
	MultiBrandHeader,
	FooterLandingSimple,
	MultiproductHeroForm,
	NewsDistributor,
	OpenDayForm,
	Overview,
	OverviewEcommerce,
	PT_InfoRequestForm,
	ProductHeroForm,
	ProgramBox,
	RelatedPrograms,
	Search,
	SocialShare,
	SponsorsLogos,
	SponsorsLogosLightbox,
	StaffBio,
	StaffCarouselLanding,
	StaffDistributor,
	StickyFooterCTA,
	StudyAgregator,
	StudyDistributor,
	StudyFixedMenu,
	StudyStaff,
	Table,
	Testimonials,
	TestimonialsLanding,
	VerticalCardCollection,
	VideoPlaylist,
	FAQ,
	JobOfferTextBlock,
	JobOfferTextBlockWithIcon,
	JobOfferDistributor,
	LandingEventList,
	LandingEventForm,
	LandingInfoForm,
	FormType,
	FormTypeGenericInfoLanding,
	FormTypeProductLanding,
	FormTypeDualProductLanding,
	FormTypeAreaLanding,
	FormTypeDualAreaLanding,
	FormTypeOtherInfoLanding,
	LandingStudyDistributor,
	FormTypeMultievent,
	FormTypeWebinar,
	FormTypeEasyPack,
	LandingIntroduction,
	LandingQuickFacts,
	ViewMore,
	LandingCTA,
	Filters,
	LandingTMGForm,
	FormTypeTMG,
	FormTypeMultiProduct,
};

const modules = mapLibrary({
	...EXPORTS,
});

const ModuleFromList = (props) => {
	return (
		<Suspense fallback={null}>
			<Component libComponents={modules} {...props} />
		</Suspense>
	);
};

export default EXPORTS;

export {
	Accordion,
	AddressCollection,
	AlumniListPagination,
	AlumniForm,
	AdmissionAttachmentsForm,
	AdmissionForm,
	AwardForm,
	AwardsDistributor,
	BasicContent,
	BlogCategoriesMenu,
	BlogDistributor,
	CardCollection,
	CareerOpportunities,
	CarouselSlide,
	ContactForm,
	CypherCollection,
	Distributor,
	DownloadBox,
	EnhancedMultiproductHeroForm,
	EnhancedProductHeroForm,
	EventAgenda,
	EventDistributor,
	EventHeroForm,
	FeaturedAudiovisual,
	FeaturedBlock,
	FeaturedImage,
	Footer,
	FooterLanding,
	GroupingCollection,
	Header,
	HeaderLanding,
	HeaderOneLine,
	HeaderTwoLines,
	HeroCard,
	HeroEcommerce,
	HeroHome,
	HeroStudy,
	HeroLanding,
	HighLightedCTA,
	HighlightedCarousel,
	HighlightedDegrees,
	HighlightedDownload,
	HorizontalTabs,
	InfoRequestForm,
	InnerTitle,
	Introduction,
	LandingForm,
	ModuleWrapper,
	MultiBrandHeader,
	FooterLandingSimple,
	MultiproductHeroForm,
	NewsDistributor,
	OpenDayForm,
	Overview,
	OverviewEcommerce,
	PT_InfoRequestForm,
	ProductHeroForm,
	ProgramBox,
	RelatedPrograms,
	Search,
	SocialShare,
	SponsorsLogos,
	SponsorsLogosLightbox,
	StaffBio,
	StaffCarouselLanding,
	StaffDistributor,
	StickyFooterCTA,
	StudyAgregator,
	StudyDistributor,
	StudyFixedMenu,
	StudyStaff,
	Table,
	Testimonials,
	TestimonialsLanding,
	VerticalCardCollection,
	VideoPlaylist,
	FAQ,
	JobOfferTextBlock,
	JobOfferTextBlockWithIcon,
	JobOfferDistributor,
	LandingEventList,
	LandingEventForm,
	LandingInfoForm,
	FormType,
	FormTypeGenericInfoLanding,
	FormTypeProductLanding,
	FormTypeDualProductLanding,
	FormTypeAreaLanding,
	FormTypeDualAreaLanding,
	FormTypeOtherInfoLanding,
	LandingStudyDistributor,
	FormTypeMultievent,
	FormTypeWebinar,
	FormTypeEasyPack,
	LandingIntroduction,
	LandingQuickFacts,
	ViewMore,
	LandingCTA,
	Filters,
	FormTypeMultiProduct,
	LandingTMGForm,
	FormTypeTMG,
};

export { ModuleFromList };
