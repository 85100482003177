import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Featured Image",
	component: "FeaturedImage",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
					advanced: true,
					hideable: true,
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
					mandatory: true,
					helptext: "Recommended minimum image size: 1120x377",
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					hideable: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{ ...createThemeStyles("ipam-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("iade-theme", ["default", "defaultAlt"]) },
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/FeaturedImage/thumbnail.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/FeaturedImage/thumbnail_1x.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "FeaturedImage",
		title: { content: "Title", tag: "h2" },
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		veil: 0.1,
		subtitle: "Lorem Ipsum",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
		button: {
			component: "Button",
			buttonText: "Link",
			appearance: "link",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedImage_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedImage_x2.png`,
	},
});
