import thumbnail from "@axthumbs/modules/FormTypeProductLanding.png";
import FormFields from "@schemas/commons/FormFields";
import PrivacyPoliceFormType from "@schemas/commons/PrivacityPoliceFormType";
import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Alumni Form",
	component: "AlumniForm",
	category: "content",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				...TitleAndSubtitle.content,
				...FormFields.content.filter(
					(field) => !["stickyCTA"].includes(field.key)
				),
				...PrivacyPoliceFormType.content,
				{
					title: "Related Content",
					type: "Wysiwyg",
					key: "relatedContent",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "AlumniForm",
		...TitleAndSubtitle.defaults,
		...FormFields.defaults,
		...PrivacyPoliceFormType.default,
		relatedContent:
			"<h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4><p><br></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.</p>",
	},

	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
});
