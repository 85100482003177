import { instance } from "@instance-config";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");
const isIADEES = instance.includes("iadeES");

const KNOWLEDGE_AREA = {
	title: "Knowledge Area",
	type: "AsyncCheckGroup",
	source: "KNOWLEDGE_AREA",
	key: "knowledgeArea",
	from: "knowledgeArea",
};

const CAMPUS = {
		title: "Academic Campus",
		type: "AsyncCheckGroup",
		sources: "CAMPUS",
		key: "academicCampusData",
		from: "academicCampusData"
	}

const LOCATION = {
	title: "Location",
	type: "AsyncCheckGroup",
	source: "LOCATION",
	key: "location",
	from: "location",
};

const LANGUAGE = {
	title: "Language",
	type: "AsyncCheckGroup",
	source: "LANGUAGE",
	key: "language",
	from: "language",
};

const STUDY = {
	title: "Study",
	dataPacks: ["STUDY"],
	local: false,
	taxonomy: false,
	fromPage: true,
	translate: true,
	schema: {
		templates: ["StudyDetail"],
		fields: [
			{
				title: "Program type",
				type: "AsyncCheckGroup",
				source: "STUDY_TYPE",
				key: "programType",
				from: "programType",
			},
			{
				title: "Salesforce ID",
				type: "TextField",
				key: "salesforceID",
				from: "salesforceID",
			},
			{
				title: "Landing Description",
				type: "TextField",
				humanReadable: true,
				key: "landingDescription",
				from: "landingDescription",
			},
			{
				title: "Landing Image",
				type: "ImageField",
				key: "landingImage",
				from: "landingImage",
			},
			{
				title: "Title",
				type: "TextField",
				humanReadable: true,
				key: "title",
				from: "programTitle",
				indexable: true,
			},
			{
				title: "Program Title",
				type: "TextField",
				humanReadable: true,
				key: "programTitle",
				from: "programTitle",
				indexable: true,
			},
			{
				title: "Program Alternative Title",
				type: "TextField",
				humanReadable: true,
				key: "programAlternativeTitle",
				indexable: true,
				from: "programAlternativeTitle",
			},
			{
				title: "Description",
				type: "TextField",
				humanReadable: true,
				key: "description",
				from: "description",
			},
			{
				title: "Alternative Description",
				type: "TextField",
				humanReadable: true,
				key: "alternativeDescription",
				from: "alternativeDescription",
			},
			{
				title: "Learning option",
				type: "RichText",
				humanReadable: true,
				key: "learningOption",
				from: "learningOption",
			},
			{
				title: "Dedication",
				type: "TextField",
				humanReadable: true,
				key: "dedication",
				from: "dedication",
			},
			{
				title: "Credits",
				type: "RichText",
				humanReadable: true,
				key: "credits",
				from: "credits",
			},
			{
				title: "Start",
				type: "RichText",
				humanReadable: true,
				key: "start",
				from: "start",
			},
			{
				title: "Start Date",
				type: "DateField",
				humanReadable: true,
				key: "startDate",
				from: "startDate",
			},
			{
				title: "Hero Section",
				type: "ComponentArray",
				key: "heroSection",
				from: "heroSection",
			},
			{
				title: "Course Mode",
				type: "AsyncSelect",
				source: "COURSE_MODE",
				key: "courseMode",
				from: "courseMode",
			},
			{
				title: "Academic Recognition",
				key: "academicRecognition",
				type: "RadioGroup",
				from: "academicRecognition",
			},
			{
				title: "Duration",
				key: "duration",
				type: "ConditionalField",
				from: "duration",
			},
			{
				title: "Years",
				key: "years",
				type: "NumberField",
				from: "years",
			},
			{
				title: "Months",
				key: "months",
				type: "NumberField",
				from: "months",
			},
			{
				title: "Weeks",
				key: "weeks",
				type: "NumberField",
				from: "weeks",
			},
			{
				title: "Days",
				key: "days",
				type: "NumberField",
				from: "days",
			},
		],
		searchFrom: ["programType"],
	},
	clone: null,
	defaultValues: null,
};

if(isIPAM || isIADE){
	STUDY.schema.fields.push(CAMPUS);
}

if (isIADE || isIADEES || isIPAM) {
	STUDY.schema.fields.push(KNOWLEDGE_AREA);
}

if (!!isIPAM || !!isIADE || isIADEES) {
	STUDY.schema.fields.push(LOCATION);
	STUDY.schema.fields.push(LANGUAGE);
}

export default { STUDY };
