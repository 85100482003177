import { instance } from "@instance-config";
import mountSchemaAndQuickFactsFields from "@schemas/commons/FieldsQuickFacts";
import mountSchema from "@schemas/commons/MountSchema";

const isIADE = instance.includes("IADE");
const isIADEES = instance.includes("iadeES");
const isIPAM = instance.includes("IPAM");
const isCEG = instance.includes("CEG");

//const programTypeSource = isCEG ? "STUDY_LANDING_TYPE" : "STUDY_TYPE";

const StudyData = {
	content: {
		title: {
			title: "Program Title",
			type: "TextField",
			humanReadable: true,
			key: "programTitle",
			mandatory: true,
			helptext: "Structured data",
			indexable: true,
		},
		alternativeTitle: {
			title: "Program Alternative Title",
			type: "TextField",
			humanReadable: true,
			key: "programAlternativeTitle",
			indexable: true,
		},
		description: {
			title: "Description",
			type: "TextField",
			humanReadable: true,
			key: "description",
			mandatory: true,
			helptext: "Structured data",
		},
		alternativeDescription: {
			title: "Alternative Description",
			type: "TextField",
			humanReadable: true,
			key: "alternativeDescription",
		},
		landingDescription: {
			title: "Landing description",
			type: "TextField",
			humanReadable: true,
			key: "landingDescription",
		},
		landingImage: {
			title: "Landing Image",
			type: "ImageField",
			key: "landingImage",
		},
		salesforceID: {
			title: "Salesforce ID",
			type: "TextField",
			key: "salesforceID",
		},
		programType: {
			title: "Program type",
			type: "AsyncCheckGroup",
			source: "STUDY_TYPE",
			key: "programType",
			helptext: "Structured data",
		},
		knowledgeArea: {
			title: "Knowledge Area",
			type: "AsyncCheckGroup",
			source: "KNOWLEDGE_AREA",
			key: "knowledgeArea",
		},
		campus: {
			title: "Campus",
			type: "AsyncCheckGroup",
			source: "CAMPUS",
			key: "campus",
		},
		startDate: {
			title: "Start Date",
			type: "DateField",
			key: "startDate",
		},
		dedication: {
			title: "Dedication",
			type: "RichText",
			key: "dedication",
			mandatory: true,
		},
		duration: {
			title: "Duration",
			type: "ConditionalField",
			key: "duration",
			options: [
				{ name: "years", title: "Years", value: "years" },
				{ name: "months", title: "Months", value: "months" },
				{ name: "weeks", title: "Weeks", value: "weeks" },
				{ name: "days", title: "Days", value: "days" },
			],
			fields: [
				{
					title: "Years",
					type: "NumberField",
					key: "years",
					condition: "years",
					min: 1,
					max: 5,
				},
				{
					title: "Months",
					type: "NumberField",
					key: "months",
					condition: "months",
					min: 1,
					max: 24,
				},
				{
					title: "Weeks",
					type: "NumberField",
					key: "weeks",
					condition: "weeks",
					min: 1,
					max: 12,
				},
				{
					title: "Days",
					type: "NumberField",
					key: "days",
					condition: "days",
					min: 1,
					max: 120,
				},
			],
		},
		academicRecognition: {
			title: "Academic Recognition",
			key: "academicRecognition",
			type: "RadioGroup",
			options: [
				{
					value: "official",
					title: "Official",
					name: "official",
				},
				{
					value: "nonofficial",
					title: "Non-official",
					name: "nonofficial",
				},
			],
		},
	},
	defaults: {
		title: "",
		alternativeTitle: "",
		description: "",
		alternativeDescription: "",
		landingDescription: "",
		landingImage: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		salesforceID: "",
		programType: [],
		knowledgeArea: [],
		campus: [],
		startDate: "",
		dedication: "",
		duration: "years",
		years: 3,
		months: null,
		weeks: null,
		days: null,
		academicRecognition: "official",
	},
};

const getFields = (overrides = {}) => {
	const fields = [
		{ ...(overrides?.title ? overrides.title : StudyData.content.title) },
		{
			...(overrides?.description
				? overrides.description
				: StudyData.content.description),
		},
		{
			...(overrides?.landingImage
				? overrides.landingImage
				: StudyData.content.landingImage),
		},
		{
			...(overrides?.salesforceID
				? overrides.salesforceID
				: StudyData.content.salesforceID),
		},
		{
			...(overrides?.programType
				? overrides.programType
				: StudyData.content.programType),
		},
	];

	if (!isCEG) {
		fields.push({
			...(overrides?.alternativeTitle
				? overrides.alternativeTitle
				: StudyData.content.alternativeTitle),
		});
		fields.push({
			...(overrides?.alternativeDescription
				? overrides.alternativeDescription
				: StudyData.content.alternativeDescription),
		});
		fields.push({
			...(overrides?.landingDescription
				? overrides.landingDescription
				: StudyData.content.landingDescription),
		});
		fields.push({
			...(overrides?.startDate
				? overrides.startDate
				: StudyData.content.startDate),
		});
		fields.push({
			...(overrides?.dedication
				? overrides.dedication
				: StudyData.content.dedication),
		});
		fields.push({
			...(overrides?.duration
				? overrides.duration
				: StudyData.content.duration),
		});
		fields.push({
			...(overrides?.academicRecognition
				? overrides.academicRecognition
				: StudyData.content.academicRecognition),
		});
	}

	if (isIADE || isIADEES || isIPAM) {
		fields.push({
			...(overrides?.knowledgeArea
				? overrides.knowledgeArea
				: StudyData.content.knowledgeArea),
		});
	}

	if (isIADE || isIPAM) {
		fields.push({
			...(overrides?.campus
				? overrides.campus
				: StudyData.content.campus),
		});
	}

	return fields;
};

const getDefaults = () => {
	const defaults = {};
	defaults.title = StudyData.defaults.title;
	defaults.description = StudyData.defaults.description;
	defaults.programType = StudyData.defaults.programType;
	defaults.salesforceID = StudyData.defaults.salesforceID;
	defaults.landingImage = StudyData.defaults.landingImage;

	if (!isCEG) {
		defaults.landingDescription = StudyData.defaults.landingDescription;
		defaults.alternativeTitle = StudyData.defaults.alternativeTitle;
		defaults.alternativeDescription = StudyData.defaults.alternativeDescription;
		defaults.startDate = StudyData.defaults.startDate;
		defaults.dedication = StudyData.defaults.dedication;
		defaults.duration = StudyData.defaults.duration;
		defaults.years = StudyData.defaults.years;
		defaults.months = StudyData.defaults.months;
		defaults.weeks = StudyData.defaults.weeks;
		defaults.days = StudyData.defaults.days;
		defaults.academicRecognition = StudyData.defaults.academicRecognition;
	}

	if (isIADE || isIADEES || isIPAM) {
		defaults.knowledgeArea = StudyData.defaults.knowledgeArea;
	}

	if (isIADE || isIPAM) {
		defaults.campus = StudyData.defaults.campus;
	}

	return defaults;
};

const mountStudySchema = ({ schemaBase, overrides }) => {
	const fields = getFields(overrides);
	const defaults = getDefaults();
	const schema = mountSchema({ schemaBase, fields, defaults });

	return mountSchemaAndQuickFactsFields({ schemaBase: schema });
};

export default mountStudySchema;
